import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Icon } from "react-icons-kit"
import { checkCircle } from "react-icons-kit/fa/checkCircle"

import MainSection from "../components/MainSection"
import ServicesSection from "../components/ServicesSection"
import PortfolioSection from "../components/PortfolioSection"
import ContactSection from "../components/ContactSection"
import CtaSection from "../components/LayoutComp/CtaSection"

import CtaButton from "../components/LayoutComp/CtaButton"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "homepage" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery {
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const images = data.imagesJson

  let gallery = []

  images.gallery.forEach(result => {
    gallery = [...gallery, result.image.childImageSharp.fluid]
  })

  return (
    <Layout>
      <SEO title="Home" />
      <MainSection image={images.background.childImageSharp.fluid}>
        <h1>Bob Keach &amp; Sons Carpentry</h1>
        <h2>
          With over 35 years in business, we offer the experience and passion,
          <br className="d-none d-xl-inline" />
          to exceed your renovation expectations.
        </h2>
      </MainSection>

      <section id="main-about">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={11}
              lg={6}
              xl={5}
              className="align-self-xl-end pb-5 pb-lg-0"
            >
              <h2 className="pb-4">
                35 years of trust, quality, and family values
              </h2>
              <p>
                Bob Keach &amp; Sons Carpentry is a family-owned and operated
                company, offering frame to finish carpentry and general
                contracting services for residential homeowners.
              </p>

              <p className="text-dark">
                <strong>
                  We are a hands-on team of fully licensed, and insured
                  professionals working together to achieve our client’s
                  <br className="d-none d-lg-inline" /> dream homes.
                </strong>
              </p>
              <CtaButton to="/about-us" type="outline-primary small mt-3">
                Learn More About Us
              </CtaButton>
            </Col>
            <Col xs={11} lg={6} xl={{ span: 6, offset: 1 }} id="aboutColumn">
              <Img
                className="card-img-top d-none d-lg-block"
                fluid={images.firstSection.childImageSharp.fluid}
                alt="Remodeled bedroom"
              />
              <div className="benefitsList">
                <ul className="list-unstyled">
                  <li>
                    <Icon size={28} icon={checkCircle} />
                    <strong>
                      Over 35 years <br />
                      of experience
                    </strong>
                  </li>
                  <li>
                    <Icon size={28} icon={checkCircle} />
                    <strong>
                      Fully insured <br />
                      and licensed
                    </strong>
                  </li>
                  <li>
                    <Icon size={28} icon={checkCircle} />
                    <strong>
                      Long list of <br />
                      satisfied clients
                    </strong>
                  </li>
                  <li>
                    <Icon size={28} icon={checkCircle} />
                    <strong>
                      Stress-free <br />
                      experience
                    </strong>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ServicesSection />

      <CtaSection>
        With years of experience, we offer you
        <br className="d-none d-lg-inline" /> an easy and stress-free remodeling
        experience
      </CtaSection>
      <PortfolioSection
        images={[
          "outdoor-living/35.jpg",
          "foxboro-addition/20.jpg",
          "foxboro-addition/25.jpg",
          "outdoor-living/39.jpg",
          "outdoor-living/38.jpg",
          "mansfield-addition/28.jpg",
          "foxboro-addition/27.jpg",
          "custom-built-ins/12.jpg",
          "kitchens/07.jpg",
        ]}
        gallery={gallery}
      >
        <Row className="justify-content-center">
          <Col xs={12} md={11} lg={4}>
            <h3 className="h2">Best Results </h3>
          </Col>
          <Col xs={12} md={10} lg={8}>
            <p>
              We have earned a strong reputation for doing beautiful work that
              meets timelines and stays within budget. A large portion of our
              business comes from both repeat and referral customers.
            </p>
            <p>
              We always put in the extra effort to get the job done right. No
              matter how big or small, we approach every project with the same
              attention to detail and quality customer service.
            </p>
          </Col>
        </Row>
      </PortfolioSection>

      <ContactSection />
    </Layout>
  )
}

export default IndexPage
